
.search-paper {
  padding: 2px 4px;
  display: flex;
  align-items: center;
  width: 300px;
}

.search-input {
  margin-left: 8px;
  flex: 1;
}

